@import '~ShopUiBundle/components/molecules/button-dropdown/button-dropdown';
@import '~ShopUiBundle/components/atoms/btn/btn';

@mixin catalog-filter-dropdown($name: '.catalog-filter-dropdown') {
    @include shop-ui-button-dropdown($name);

    $size-medium: #{$name}--size-medium;

    #{$name} {
        $self: &;
        $header-modifier: '#{&}--header';
        $footer-modifier: '#{&}--footer';
        $max-height: 470px;

        &__content-wrapper {
            width: 100%;
            max-width: 100%;
            padding-top: $spacing-xs;
            padding-bottom: $spacing-xs;

            @include breakpoint(mediumsmall) {
                width: auto;
                max-width: none;
            }
        }

        &__content-inner {
            display: flex;
            position: relative;
            flex-direction: column;
            padding: $spacing-md 0 $spacing-md $spacing-md;
            border: 2px solid $color-gray-10;
        }

        &__header,
        &__footer {
            flex-basis: 100%;
            flex-grow: 1;
            padding-right: $spacing-md;
        }

        &__header {
            padding-bottom: $spacing-md;
        }

        &__footer {
            padding-top: $spacing-md;
        }

        &__content {
            // (paddings * 2) + (border * 2)
            $content-height-wrapper: $max-height - (($spacing-md * 2) + (2px * 2));

            // header + padding
            $content-height-header: 50px + $spacing-md;

            // footer + padding
            $content-height-footer: 50px + $spacing-md;

            position: relative;
            width: 100%;
            max-width: 100%;
            max-height: $content-height-wrapper;
            // stylelint-disable-next-line declaration-no-important
            padding-right: $spacing-md !important;

            @include breakpoint(mediumsmall) {
                width: 320px - ($spacing-md) - (2px * 2);
            }

            #{$header-modifier} & {
                max-height: $content-height-wrapper - $content-height-header;
            }

            #{$footer-modifier} & {
                max-height: $content-height-wrapper - $content-height-footer;
            }

            #{$header-modifier}#{$footer-modifier} & {
                max-height: $content-height-wrapper - $content-height-header - $content-height-footer;
            }
        }

        &__button {
            $icon-element: '#{&}-icon';

            @include shop-ui-make-btn {
                border-width: 2px;
            }
            // stylelint-disable-next-line order/order
            @include shop-ui-make-btn-size-default($shop-ui-btn-size-sm, true);

            @include shop-ui-make-catalog-filter-dropdown-button-theme-default-color(&, $self, $icon-element);

            display: flex;

            #{$size-medium} & {
                @include shop-ui-make-btn-size-default($shop-ui-btn-size-md, true);
            }
        }

        &__search {
            @include font-default;
            @include font-size-medium;

            display: flex;
            flex-grow: 1;
            height: 50px;
            background-color: $color-gray-90;
            border-radius: $global-button-border-radius;
        }

        &__search-input {
            // Avoid specifity problems with _input.scss
            #{$name} & {
                @include font-default;
                @include font-size-medium;

                display: block;
                flex-basis: 100%;
                height: 100%;
                margin: 0;
                padding: 0 0 0 $spacing-md;
                background-color: transparent;
                // stylelint-disable-next-line declaration-no-important
                border: 0 !important;

                &::placeholder {
                    @include font-default((
                        'color': $color-gray-50,
                    ));
                    @include font-size-medium;
                }
            }
        }

        &__search-submit-icon {
            color: $color-black;
        }

        &__search-submit {
            display: block;
            height: 100%;
            padding: 0 $spacing-md;
            cursor: pointer;
        }
    }

    @content;
}

@mixin shop-ui-make-catalog-filter-dropdown-button-theme-default-color(
    $target,
    $parent,
    $icon-element,
) {
    $disabled: '#{$parent}--disabled';
    $open: '#{$parent}--open';
    $active: '#{$parent}--active';

    &,
    &:visited,
    &:focus {
        background-color: $color-white;
        border-color: $color-gray-70;
        color: $color-gray-10;

        & #{$icon-element} {
            color: $color-gray-50;
        }
    }

    &:hover {
        background-color: $color-white;
        border-color: $color-gray-50;
        color: $color-gray-10;

        & #{$icon-element} {
            color: $color-gray-50;
        }
    }

    &:active,
    #{$open} &,
    #{$active} & {
        background-color: $color-white;
        border-color: $color-gray-10;
        color: $color-gray-10;

        & #{$icon-element} {
            color: inherit;
        }
    }

    &#{$target}:disabled,
    #{$disabled} & {
        background-color: $color-white;
        border-color: $color-gray-70;
        color: $color-gray-70;

        & #{$icon-element} {
            color: inherit;
        }
    }

    @content;
}
