@import '~ShopUiBundle/styles/shared';

@mixin catalog-bundle-catalog-products-dynamic($name: '.catalog-products-dynamic') {
    #{$name} {
        display: flex;
        position: relative;
        justify-content: center;
        width: 100%;

        &__form {
            display: none;
        }

        &__loading {
            width: 64px;
        }
    }
}
