@import '~ShopUiBundle/styles/shared';
@import '~ShopUiBundle/components/atoms/text-link/text-link';

@mixin catalog-filter-single($name: '.catalog-filter-single') {
    $disabled-label: '#{$name}__label--disabled';
    $checked-label: '#{$name}__label--checked';
    $padding: $spacing-md;
    $icon-size: 11px;
    $icon-placeholder-size: 18px;

    #{$name} {
        display: block;
        position: relative;

        &__list {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        &__list-item {
            display: block;
            position: relative;
            align-items: center;
            border-bottom: 1px solid $color-gray-80;

            &:last-child {
                border-bottom: none;
            }
        }

        &__label {
            $self: &;

            // Avoid specifity problems with _label.scss
            #{$name} & {
                @include font-default;
                @include font-size-medium;

                display: flex;
                position: relative;
                align-items: center;
                justify-content: space-between;
                height: 50px;
                padding-left: $padding;
                padding-right: $padding + $icon-placeholder-size;
                cursor: pointer;
                user-select: none;

                &,
                &:visited,
                &:active,
                &:focus {
                    color: $color-gray-10;
                }

                &:hover {
                    background-color: $color-gray-90;
                }

                &--disabled {
                    // stylelint-disable-next-line declaration-no-important
                    color: $color-gray-70 !important;
                    cursor: default;
                }

                &--checked {
                    // stylelint-disable-next-line declaration-no-important
                    color: $color-gray-10 !important;
                    cursor: default;
                }
            }
        }

        &__title {
            display: block;
            overflow: hidden;
            color: inherit;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &__icon {
            display: none;
            position: absolute;
            top: 50%;
            right: $padding;
            width: $icon-placeholder-size;
            height: $icon-placeholder-size;
            transform: translateY(-50%);

            #{$checked-label} & {
                display: block;
            }
        }

        &__icon-item {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: $icon-size;
            height: $icon-size;
            transform: translate(-50%, -50%);
        }
    }

    @content;
}
