@use 'sass:math';

@import '~ShopUiBundle/styles/shared';

@mixin product-bundle-select-size-link($name: '.select-size-link') {
    $disabled: '#{$name}--disabled';
    $active: '#{$name}--active';
    $hovered: '#{$name}:hover:not(#{$active})';
    $breakpoint: mediumlarge;

    #{$name} {
        @include font-din-regular;

        display: flex;
        position: relative;
        align-items: center;
        justify-content: space-between;
        background-color: $color-white;
        border: 1px solid $color-gray-80;
        border-radius: $global-button-border-radius;
        color: $color-gray-20;
        transition: color ease 0.2s, border-color ease 0.2s, background-color ease 0.2s;

        &#{$active} {
            border-color: $color-gray-10;
        }

        &#{$hovered} {
            background-color: $color-gray-90;
            border-color: $color-gray-80;
        }

        &#{$disabled} {
            // stylelint-disable-next-line declaration-no-important
            background-color: $color-white !important;

            &:not(#{$active}) {
                // stylelint-disable-next-line declaration-no-important
                border-color: $color-gray-80 !important;
            }
        }

        &__link {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: flex-start;
            padding: $sp-160 0 $sp-160 $sp-200;
            color: $color-gray-10;
            text-decoration: none;

            @include breakpoint($breakpoint) {
                padding: $sp-240 0 $sp-240 $sp-200;
            }

            #{$active} & {
                pointer-events: none;
            }
        }

        &__key {
            @include font-din-medium;

            display: block;
            padding-right: $sp-200;
            font-size: 24px;
            letter-spacing: $global-title-letter-spacing;
            line-height: 1;

            @include breakpoint(medium) {
                min-width: 100px;
                padding-right: $sp-240;
            }

            @include breakpoint($breakpoint) {
                font-size: 32px;
            }

            #{$disabled} & {
                // stylelint-disable-next-line declaration-no-important
                color: $color-gray-20 !important;
            }
        }

        &__text {
            font-size: 12px;
            color: $color-gray-10;
            line-height: #{ math.div(16, 12) };

            @include breakpoint($breakpoint) {
                font-size: 14px;
                line-height: #{ math.div(20, 14) };
            }

            #{$disabled} & {
                // stylelint-disable-next-line declaration-no-important
                color: $color-gray-20 !important;
            }
        }

        &__availability {
            display: flex;
            align-items: center;
            margin-left: auto;
            padding: 0 $sp-200 0 $sp-080;
            font-size: 12px;
            color: $color-gray-10;
            line-height: 1;
            text-align: right;
            text-decoration: none;

            @include breakpoint($breakpoint) {
                font-size: 14px;
                line-height: #{ math.div(20, 14) };
            }

            #{$disabled} & {
                // stylelint-disable-next-line declaration-no-important
                color: $color-gray-20 !important;
            }

            &--link {
                justify-content: flex-end;
                padding: $sp-160 $sp-200 $sp-160 $sp-080;
                font-size: 12px;
                line-height: #{ math.div(16, 12) };
                white-space: normal;
                text-align: right;
                text-decoration: underline;

                @include breakpoint($breakpoint) {
                    padding: $sp-240 $sp-200 $sp-240 $sp-080;
                    font-size: 14px;
                    line-height: #{ math.div(20, 14) };
                }
            }

            &-circle {
                display: none;
                width: 8px;
                height: 8px;
                margin-left: $sp-080;
                border-radius: 50%;

                &--green {
                    display: inline-block;
                    background-color: $color-availability-in-stock;
                }

                &--yellow {
                    display: inline-block;
                    background-color: $color-availability-limited;
                }

                &--red {
                    display: inline-block;
                    background-color: $color-availability-out-of-stock;
                }
            }
        }
    }
}
