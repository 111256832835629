@import '~ShopUiBundle/styles/shared';

@mixin catalog-bundle-bike-detail-availability($name: '.bike-detail-availability') {
    $green: '#{$name}--green';
    $yellow: '#{$name}--yellow';
    $red: '#{$name}--red';

    #{$name} {
        display: flex;
        align-items: center;

        &__icon {
            width: $sp-080;
            height: $sp-080;
            margin-right: $sp-080;

            #{$green} & {
                color: $color-availability-in-stock;
            }

            #{$yellow} & {
                color: $color-availability-limited;
            }

            #{$red} & {
                color: $color-availability-out-of-stock;
            }
        }

        &__text {
            @include font-text-small((
                'color': $color-gray-50,
            ));

            margin: 0;

            &--clarification {
                color: $color-availability-out-of-stock;
            }
        }

        &__link {
            color: $color-gray-20;
        }
    }
}
