@import '~ShopUiBundle/styles/shared';

@mixin product-bundle-select-size-modal-model-info($name: '.select-size-modal-model-info') {

    #{$name} {
        display: flex;
        align-items: center;

        &__text {
            @include font-text-small;

            padding-left: $spacing-md;
        }

        &__name {
            color: $color-gray-20;
        }

        &__color {
            color: $color-gray-50;
        }
    }
}
