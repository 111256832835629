@import '~ShopUiBundle/styles/shared';

@mixin product-bundle-filter-tag($name: '.filter-tag') {
    $height: 25px;

    #{$name} {
        $hover: '#{&}:hover';

        display: inline-flex;
        align-items: center;
        height: $height;
        padding: $spacing-xs;
        background-color: $color-gray-90;
        border-radius: $global-button-border-radius;
        cursor: pointer;

        &__link {
            display: flex;
            align-items: center;
            text-decoration: none;
        }

        &__title {
            @include font-default;
            @include font-size-small;

            margin-right: $spacing-sm;
        }

        &__icon {
            width: 10px;
            height: 10px;
            color: $color-gray-50;
            transition: color $global-button-transition-duration ease-in-out;

            #{$hover} & {
                color: $color-gray-10;
            }
        }
    }
}
