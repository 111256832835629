@import '~ShopUiBundle/styles/shared';

@mixin catalog-filter-tags($name: '.catalog-filter-tags') {
    #{$name} {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        margin-right: -#{$spacing-md};

        @include breakpoint(mediumlarge) {
            margin-right: -#{$spacing-sm};
        }

        &__tag,
        &__reset-link {
            margin-bottom: $spacing-md;
            margin-right: $spacing-md;

            @include breakpoint(mediumlarge) {
                margin-bottom: $spacing-sm;
                margin-right: $spacing-sm;
            }
        }
    }
}
