@import '~ShopUiBundle/styles/shared';
@import '~ShopUiBundle/components/atoms/form-input-abstract/form-input-abstract';

@mixin catalog-filter-range($name: '.catalog-filter-range') {
    $rendered: '#{$name}--rendered';
    $dragging: '#{$name}--dragging';
    $slider-height: 20px;
    $icon-size: 40px;
    $border-width: 2px;

    #{$name} {
        $root: &;

        &__slider {
            display: block;
            position: relative;
            padding: $slider-height ($slider-height * 0.5);
            user-select: none;
        }

        &__slider-wrapper {
            display: block;
            position: relative;
            height: $slider-height;
            cursor: pointer;
            user-select: none;

            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                height: 1px;
                background-color: $color-gray-80;
                transform: translateY(-50%);
            }
        }

        &__slider-bar {
            display: block;
            position: absolute;
            top: 50%;
            left: 0%;
            right: 0%;
            height: 1px;
            visibility: hidden;
            background-color: $color-gray-10;
            transform: translateY(-50%);
            will-change: left right;
            user-select: none;

            #{$rendered} & {
                visibility: visible;
                transition-property: left right;
                transition-duration: 0.1s;
            }

            #{$dragging} & {
                transition-duration: 0s;
            }
        }

        &__slider-min,
        &__slider-max {
            @include font-default;
            @include font-size-large;

            display: block;
            position: absolute;
            top: 50%;
            left: 0%;
            width: $slider-height;
            height: $slider-height;
            visibility: hidden;
            background-color: $color-gray-10;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            cursor: grab;
            will-change: left;
            user-select: none;

            #{$rendered} & {
                visibility: visible;
                transition-property: left;
                transition-duration: 0.1s;
            }

            #{$dragging} & {
                transition-duration: 0s;
            }
        }

        &__slider-max {
            left: 100%;
        }

        &__inputs {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: $spacing-sm 0 $spacing-md 0;
        }

        &__input-left,
        &__input-right {
            display: block;
            position: relative;
            flex-basis: 100%;
        }

        &__input-range-to {
            display: block;
            padding: 0 $spacing-md;
            user-select: none;
        }

        &__input-min,
        &__input-max {
            // Avoid specifity Problems
            #{$root} & {
                display: block;
                position: relative;
                height: 50px;
                margin: 0;
                padding: $spacing-sm ($icon-size - $border-width) $spacing-sm $spacing-md;
                background-color: $color-white;
                border: $border-width solid $color-gray-80;
                border-radius: $spacing-xs;

                &:focus {
                    border: $border-width solid $color-gray-10;
                }
            }
        }

        &__input-icon {
            display: block;
            position: absolute;
            top: 50%;
            right: ($icon-size * 0.5) + $border-width;
            width: $icon-size;
            height: $icon-size;
            transform: translate(50%, -50%);
        }

        &__submit {
            margin-top: $spacing-md;
        }
    }

    @content;
}
