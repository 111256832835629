@import '~ShopUiBundle/styles/shared';

@mixin catalog-bundle-bike-detail-select-size-modal($name: '.bike-detail-select-size-modal') {

    #{$name} {

        &__list {
            display: flex;
            list-style: none;
            flex-direction: column;
            margin: 0 0 $sp-160 0;
            padding: 0;

            &-item {
                margin: $sp-080 0;

                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &__modal-link {
            display: block;
            padding-bottom: $sp-160;

            > span {
                vertical-align: baseline;
            }
        }

        &__model-info {
            margin-bottom: $spacing-md;
        }

        &__modal-content {
            // stylelint-disable-next-line declaration-no-important
            padding-top: $spacing-md !important;
        }
    }
}
