@import '~ShopUiBundle/styles/shared';

@mixin catalog-bundle-catalog-category-bikes-level-4($name: '.catalog-category-bikes-level-4') {
    #{$name} {
        display: block;

        &__models {
            margin-top: $sp-160;
            margin-bottom: $sp-800;

            @include breakpoint(mediumlarge up) {
                margin-top: $sp-400;
                margin-bottom: $sp-960;
            }
        }

        &__title,
        &__logo {
            margin: $sp-080 auto $sp-240;

            @include breakpoint(mediumlarge) {
                margin: $sp-200 auto $sp-640;
            }
        }

        &__logo {
            display: block;
            height: 23px;
            text-align: center;

            @include breakpoint(mediumlarge) {
                height: 54px;
            }
        }
    }
}
