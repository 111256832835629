@import '~SprykerCatalogBundle/components/molecules/catalog-filter-multiple/catalog-filter-multiple';

@mixin catalog-filter-color($name: '.catalog-filter-color') {
    @include catalog-filter-multiple($name);

    $checked-label: '#{$name}__label--checked';
    $height: 60px;
    $padding: $spacing-sm;
    $icon-placeholder-size: 50px;
    $icon-placeholder-background-size: $icon-placeholder-size - ($spacing-sm * 2);
    $icon-size: 10px;
    $icon-background-size: 15px;

    #{$name} {
        &__list-item {
            border-bottom: 0;
        }

        &__label {
            // Avoid specifity problems with _label.scss
            #{$name} & {
                @include font-size-small;

                height: $height;
                padding-left: $padding + $icon-placeholder-size;
                padding-right: $padding;
            }
        }

        &__icon {
            left: 0;
            right: auto;
            width: $icon-placeholder-size;
            height: $icon-placeholder-size;
            z-index: 1;

            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                width: $icon-placeholder-background-size;
                height: $icon-placeholder-background-size;
                z-index: 2;
                background-color: $color-gray-70;
                transform: translate(-50%, -50%);

                @each $key, $value in $global-catalog-color-map {
                    #{$name}__label--#{$key} & {
                        background-color: $value;
                        border-radius: $global-button-border-radius;
                    }
                }
            }

            &::after {
                content: '';
                display: none;
                position: absolute;
                top: 50%;
                left: 50%;
                width: $icon-background-size;
                height: $icon-background-size;
                z-index: 3;
                background-color: $color-gray-10;
                border-radius: 50%;
                transform: translate(-50%, -50%);

                #{$checked-label} & {
                    display: block;
                }

                @each $key, $value in $global-catalog-color-map {
                    @if lightness($value) < $global-catalog-color-threshold {
                        #{$name}__label--#{$key} & {
                            background-color: $color-gray-90;
                        }
                    }
                }
            }
        }

        &__icon-item {
            width: $icon-size;
            height: $icon-size;
            z-index: 4;
            color: $color-gray-90;

            @each $key, $value in $global-catalog-color-map {
                @if lightness($value) < $global-catalog-color-threshold {
                    #{$name}__label--#{$key} & {
                        color: $color-gray-10;
                    }
                }
            }
        }
    }

    @content;
}
