@import '~ShopUiBundle/styles/shared';
@import '~ShopUiBundle/components/atoms/text-link/text-link';

@mixin catalog-filter-multiple($name: '.catalog-filter-multiple') {
    $disabled-label: '#{$name}__label--disabled';
    $checked-label: '#{$name}__label--checked';
    $icon-hover: '&:not(#{$name}__label--checked) #{$name}__icon';
    $disable-label-background-hover: '#{$name}__label--disable-background-hover';
    $height: 50px;
    $padding: $spacing-md;
    $icon-size: 11px;
    $icon-placeholder-size: 18px;

    #{$name} {
        display: block;
        position: relative;

        &__list {
            display: block;
            position: relative;
            list-style: none;
            margin: 0;
            padding: 0;

            &--grid {
                display: grid;
            }
        }

        &__list-item {
            display: block;
            position: relative;
            align-items: center;
            overflow: hidden;
            border-bottom: 1px solid $color-gray-80;

            &:last-child {
                border-bottom: none;
            }

            &--hidden {
                // stylelint-disable-next-line declaration-no-important
                display: none !important;
            }
        }

        &__label {
            // Avoid specifity problems with _label.scss
            #{$name} & {
                @include font-default;
                @include font-size-medium;

                display: flex;
                position: relative;
                align-items: center;
                justify-content: space-between;
                height: $height;
                padding-left: $padding;
                padding-right: $padding + $icon-placeholder-size + $spacing-sm;
                cursor: pointer;
                user-select: none;

                &,
                &:visited,
                &:active,
                &:focus {
                    color: $color-gray-10;
                }

                &:hover {
                    &:not(#{$disable-label-background-hover}) {
                        background-color: $color-gray-90;
                    }

                    #{$icon-hover} {
                        border-color: $color-gray-70;
                    }
                }

                &--disabled {
                    // stylelint-disable-next-line declaration-no-important
                    color: $color-gray-70 !important;
                    cursor: default;
                }

                &--checked {
                    // stylelint-disable-next-line declaration-no-important
                    color: $color-gray-10 !important;
                }
            }
        }

        &__input {
            display: inline-block;
            position: absolute;
            left: -9999px;
            width: 0;
            height: 0;
            margin: 0;
            padding: 0;
            opacity: 0;
        }

        &__title {
            display: block;
            overflow: hidden;
            color: inherit;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &__icon {
            display: block;
            position: absolute;
            top: 50%;
            right: $padding;
            width: $icon-placeholder-size;
            height: $icon-placeholder-size;
            border: 2px solid $color-gray-80;
            border-radius: $global-button-border-radius;
            transform: translateY(-50%);
            transition: color $global-button-transition-duration $global-button-transition-timing-function;

            #{$checked-label} & {
                border-color: $color-gray-10;
                color: $color-gray-10;
            }
        }

        &__icon-item {
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            width: $icon-size;
            height: $icon-size;
            transform: translate(-50%, -50%);

            #{$checked-label} & {
                display: block;
            }
        }
    }

    @content;
}
