@import '~ShopUiBundle/styles/shared';

.products-grid {
    margin-top: $spacing-lg;

    &__headline {
        margin-bottom: $spacing-lg;

        &--search {
            margin-top: $spacing-md;
        }
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        margin-bottom: 15px;
    }

    &__filter-tags {
        margin: $spacing-sm 0 0 0;
    }

    &__filter-results {
        @include font-default((
            'color': $color-gray-50,
            'bold': false,
        ));
        @include font-size-small;

        margin: $spacing-sm 0 0 0;
    }
}

// Todo: remove
.toggle-dropdown.is-open .toggle-dropdown__content {
    @include breakpoint(small only) {
        position: relative;
    }
}

.catalog__pagination .basic-pagination__wrapper {
    @include breakpoint(small) {
        margin-bottom: 40px;
    }

    @include breakpoint(medium) {
        margin-bottom: 70px;
    }
}
